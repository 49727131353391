import type * as pt from "@prismicio/types"
import { getGatsbyImageData } from "@imgix/gatsby"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyInteriorPromo } from "../../../slices/PageDataBodyInteriorPromo"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyInteriorPromo
				heading={lorem.words(3)}
				subheading={lorem.words(6)}
				copy={
					[
						{ type: "paragraph", text: lorem.sentences(2), spans: [] },
					] as pt.RichTextField
				}
				link="#"
				linkText="Learn More"
				background="Blue"
				image={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1449824913935-59a10b8d2000",
					width: 640,
					layout: "fullWidth",
					aspectRatio: 891 / 594,
				})}
				imageAlt=""
				imageOnRight={false}
			/>
		</CmsGuideLayout>
	)
}

export default Component
